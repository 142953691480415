import React from 'react';
import { footerHolder } from './Footer.module.scss';

export default function Footer() {
  return (
    <footer className={footerHolder}>
      <div className="lhs">&copy; {new Date().getFullYear()} Victoria Owen Creative</div>
      <div className="rhs">
        Site by <a href="mailto:ben@lookupstudios.co.uk">Look Up!</a>
      </div>
    </footer>
  );
}

import { Link } from 'gatsby';
import React from 'react';
import { headerHolder } from './Header.module.scss';
import { upFadeIn, staggerChildren } from '../lib/variants.js';
import { AnimatePresence, motion } from 'framer-motion';

export default function Header({ location }) {
  return (
    <header className={headerHolder}>
      <Link to="/" title="Back to homepage">
        <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1555 20.7333L1.8222 1H26.4889L14.1555 20.7333Z" stroke="black" strokeWidth="1" />
          <circle cx="13.7444" cy="25.2557" r="12.7444" stroke="black" strokeWidth="1" />
        </svg>
        <AnimatePresence>
          {location.pathname !== '/' && (
            <motion.h2 variants={staggerChildren} initial="initial" animate="animate" exit="exit">
              <motion.span variants={upFadeIn}>Victoria </motion.span>
              <motion.span variants={upFadeIn}>Owen </motion.span>
              <motion.span variants={upFadeIn}>Creative.</motion.span>
            </motion.h2>
          )}
        </AnimatePresence>
      </Link>
    </header>
  );
}

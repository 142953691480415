import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { layoutHolder } from './Layout.module.scss';
import Nav from './Nav';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/styles.scss';

const variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export default function Layout({ children, location }) {
  const [navOpen, setNavOpen] = React.useState(false);
  return (
    <div className={layoutHolder}>
      <Header location={location} />
      <Nav navOpen={navOpen} setNavOpen={setNavOpen} />
      <div className="content">
        <AnimatePresence mode="wait">
          <motion.main
            key={children?.props?.path}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            onAnimationComplete={(definition) => {
              if (definition === 'animate') {
                //get hash of current location
                if (typeof window !== 'undefined') {
                  const hash = location.hash;
                  console.log(hash);
                  let element = undefined;
                  //get element with that hash
                  if (hash) element = document.querySelector(hash);

                  //scroll to that element
                  if (element) element.scrollIntoView({ behavior: 'smooth' });
                }
              }
            }}>
            {children}
          </motion.main>
        </AnimatePresence>
      </div>
      <Footer />
    </div>
  );
}

export const upFadeIn = {
  initial: {
    opacity: 0,
    y: 15,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      type: 'tween',
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -15,
    transition: {
      duration: 0.5,
      type: 'tween',
      ease: 'easeOut',
    },
  },
};

export const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.2,
      when: 'afterChildren',
    },
  },
};

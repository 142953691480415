import { Link } from 'gatsby';
import React from 'react';
import { navHolder } from './Nav.module.scss';
import { motion, AnimatePresence } from 'framer-motion';

export default function Nav({ navOpen, setNavOpen }) {
  return (
    <div className={navHolder}>
      <AnimatePresence>
        {navOpen && (
          <motion.nav initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <ul className="navList">
              <li>
                <Link to="/" onClick={() => setNavOpen(false)}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/work" onClick={() => setNavOpen(false)}>
                  Work
                </Link>
              </li>
              <li>
                <Link to="/contact/#about" onClick={() => setNavOpen(false)}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact/#contact" onClick={() => setNavOpen(false)}>
                  Contact
                </Link>
              </li>
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
      <button className={`burger ${navOpen ? 'active' : ''}`} onClick={() => setNavOpen((nO) => !nO)}>
        Open Nav
      </button>
    </div>
  );
}
